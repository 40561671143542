document.addEventListener('DOMContentLoaded', function () {

    $('.block-phone').hover(function () {
        $(this).addClass('active');
        $(this).find('.block-phone__list').slideDown(300);
    }, function () {
        $(this).removeClass('active');
        $(this).find('.block-phone__list').slideUp(300, function () {
            $(this).stop(true);
        });
    });



    if ($(window).width() > 1180) {
        $('.catalog-modal__item').hover(function () {
            $(this).addClass('active');
            $(this).find('.catalog-modal__links').addClass('active');
            $(this).siblings().removeClass('active');
            $(this).siblings().find('.catalog-modal__links').removeClass('active');
        });
    } else {
        $('.catalog-modal__item--arrow').on('click', function () {
            $(this).parent().find('.catalog-modal__links').slideDown();
        });
    }

    $('.back').on('click', function () {
        $(this).parents('.catalog-modal__links').slideUp();
    });


    $('.btn-catalog, .burger, .btn-catalog-mob').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('.catalog-modal').slideToggle(300);
        $('body').toggleClass('scroll-hide');
    });

    $('.mob-menu-show').on('click', function () {
        $('.header-top__list').slideDown();
    });

    $('.mob-close').on('click', function () {
        $('.header-top__list').slideUp();
    });

    let scrollPos = 0;
    $(window).scroll(function () {
        let st = $(this).scrollTop();
        if (st > scrollPos) {
            $('.header').addClass('scroll-hed');
        } else {
            $('.header').removeClass('scroll-hed');
        }
        scrollPos = st;
    });


});