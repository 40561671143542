document.addEventListener('DOMContentLoaded', function () {

    $('.radio-block').on('click', function () {
        $(this).parents('.deliver-item').find('.radio-block').removeClass('active');
        $(this).addClass('active');
        $(this).siblings().removeClass('active');
    });

    $('.individual').on('click', function () {
        $('.individual-hide').addClass('hide');
    });

    $('.entity').on('click', function () {
        $('.individual-hide').removeClass('hide');
    });

    $('.pickup').on('click', function () {
        $('.pickup-list').slideDown(300);
        $('.address-deliver').slideUp(300);
        $('.new-address').slideUp(300);
    });

    $('.not-pickup').on('click', function () {
        $('.pickup-list').slideUp(300);
        $('.address-deliver').slideDown(300);
    });

    $('.add-new-address').on('click', function () {
        $('.new-address').slideDown(300);
    });

    $('.old-address').on('click', function () {
        $('.new-address').slideUp(300);
    });


});