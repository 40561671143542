document.addEventListener('DOMContentLoaded', function () {

    $('.card__favorites').on('click', function () {
        let check = $(this).find('input');

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            check.prop("checked", false);
        } else {
            $(this).addClass('active');
            check.prop("checked", true);
        }
    });


    $('.check-item').on('click', function () {
        $(this).addClass('active').siblings().removeClass('active');
    });




    if ($(window).width() > 1024) {
        $(document).on('mouseover', '.card', function () {
            $(this).find('.card__option').slideDown(1);
            $(this).addClass('active');
            $(this).parents('.card-slider').find('.swiper').css('padding-bottom', '170px');
        });
        $(document).on('mouseleave', '.card', function () {
            $(this).removeClass('active');
            $(this).parents('.card-slider').find('.swiper').css('padding-bottom', '20px');
            $(this).find('.card__option').slideUp(1, function () {
                $(this).stop(true);
            });
        });
    } else {
        $('.card-btn-mob').on('click', function () {
            $('.card').removeClass('active');
            $('.card-btn-mob').removeClass('hide');
            $('.card-btn-mob').next().slideUp(1);
            $(this).next().slideDown(1);
            $(this).addClass('hide');
            $(this).parents('.card-slider').find('.swiper').css('padding-bottom', '140px');
            $(this).parents('.card').addClass('active');
        });
    }

    $(document).mouseup(function (e) {
        let container = $('.card-wrap');
        console.log(e.target);
        if (container.has(e.target).length === 0) {
            $('.card-btn-mob').removeClass('hide');
            $('.card-btn-mob').next().slideUp(1);
            $('.card-slider').find('.swiper').css('padding-bottom', '20px');
            $('.card').removeClass('active');
        }
    });

    $('.address-block__item--text').on('click', function () {
        $(this).toggleClass('active');
        $(this).next().slideToggle(300);
    });


    let checkbox = document.querySelectorAll('.checkbox');
    let btnSubmit = document.querySelectorAll('.form__btn');

    checkbox.forEach(item => {
        item.addEventListener("change", (e) => {
            if (e.target.checked) {
                btnSubmit.forEach(item => {
                    item.classList.remove('btn-disabled');
                    item.disabled = false;
                });
            } else {
                btnSubmit.forEach(item => {
                    item.classList.add('btn-disabled');
                    item.disabled = true;
                });
            }
        });
    });


    $('.show-password').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');

        if ($(this).hasClass('active')) {
            $(this).parent().find('input').attr('type', 'text');
        } else {
            $(this).parent().find('input').attr('type', 'password');
        }
    });


    $('.tab').on('click', function (event) {
        event.preventDefault();

        $($(this).siblings()).removeClass('tab-active');
        $($(this).closest('.tabs-wrapper').siblings().find('div')).removeClass('tabs-content-active');

        $(this).addClass('tab-active');
        $($(this).attr('href')).addClass('tabs-content-active');
    });

    $('.filter-item__list li').on('click', function () {
        let check = $(this).find('input');

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            check.prop("checked", false);
        } else {
            $(this).addClass('active');
            check.prop("checked", true);
        }

    });

    $('.filter-item__title').on('click', function () {
        $(this).next().slideToggle(300);
        $(this).toggleClass('active');
    });

    $('.page-filter__sorting-wrap').on('click', function () {
        $(this).toggleClass('active');
    });

    $('.chenge-card').on('click', function () {
        $(this).toggleClass('chenge');
        $('.page-filter__cards').toggleClass('chenge-grid');
    });

    $('.mob-wrap-sort__title').on('click', function () {
        $(this).toggleClass('active');
        $('.mob-wrap-sort').slideToggle(300);
    });



    $('.write-reviews__stars div').on('click', function () {
        $(this).toggleClass('active');
        $(this).prevAll().addClass('active');
        $(this).nextAll().removeClass('active');
    });




    let $range = $(".js-range-slider"),
        $inputFrom = $(".js-input-from"),
        $inputTo = $(".js-input-to"),
        instance,
        min = 0,
        max = 400,
        from = 0,
        to = 0;

    $range.ionRangeSlider({
        skin: "round",
        type: "double",
        min: min,
        max: max,
        from: 0,
        to: 180,
        onStart: updateInputs,
        onChange: updateInputs
    });
    instance = $range.data("ionRangeSlider");

    function updateInputs(data) {
        from = data.from;
        to = data.to;
        $inputFrom.prop("value", from);
        $inputTo.prop("value", to);
    }

    $inputFrom.on("input", function () {
        let val = $(this).prop("value");
        if (val < min) {
            val = min;
        } else if (val > to) {
            val = to;
        }
        instance.update({
            from: val
        });
    });
    $inputTo.on("input", function () {
        let val = $(this).prop("value");
        if (val < from) {
            val = from;
        } else if (val > max) {
            val = max;
        }
        instance.update({
            to: val
        });
    });

    $('.btn-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 400);
        return false;
    });

    const textarea = document.querySelector('textarea');

    textarea.addEventListener('keyup', function () {
        if (this.scrollTop > 0) {
            this.style.height = this.scrollHeight + "px";
        }
    });



    function progress(num) {
        $('.progress-mob__num').text(num);
        $('.profile-progress').text(num);
        $('.progress-mob__line p').css('width', `${num}%`);
    }






});