document.addEventListener('DOMContentLoaded', function () {

    const swiper = new Swiper(".promo-swiper", {
        slidesPerView: 1,
        loop: true,
        speed: 1000,
        effect: "creative",
        creativeEffect: {
            prev: {
                shadow: true,
                translate: ["-20%", 0, -1],
            },
            next: {
                translate: ["100%", 0, 0],
            },
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".promo .swiper-button-next",
            prevEl: ".promo .swiper-button-prev",
        },
    });

    const swiper2 = new Swiper(".card-slider-swiper", {
        slidesPerView: 6,
        spaceBetween: 15,
        speed: 1000,
        navigation: {
            nextEl: ".card-slider .swiper-button-next",
            prevEl: ".card-slider .swiper-button-prev",
        },
        breakpoints: {
            1310: {
                slidesPerView: 6,
            },
            1060: {
                slidesPerView: 5,
            },
            800: {
                slidesPerView: 4,
            },
            600: {
                slidesPerView: 3,
            },
            300: {
                slidesPerView: 2,
            },
        },
    });

    const swiper3 = new Swiper(".card-page-slider", {
        spaceBetween: 10,
        slidesPerView: 3,
        freeMode: true,
        watchSlidesProgress: true,
        direction: "vertical",
    });
    const swiper4 = new Swiper(".card-page-slider2", {
        spaceBetween: 10,
        direction: "vertical",
        thumbs: {
            swiper: swiper3,
        },
    });

});