const header = document.querySelector('.header'),
    modaWrap = document.querySelectorAll('.modal-wrap');

function bindModal(triggerSelector, modalSelector) {
    const trigger = document.querySelectorAll(triggerSelector),
        modal = document.querySelector(modalSelector),
        scroll = calcScroll();


    trigger.forEach(item => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            closeModal();
            modal.classList.add('active');
            document.body.style.overflow = "hidden";
            header.style.width = `calc(100% - ${scroll}px)`;
            document.body.style.marginRight = `${scroll}px`;
            modaWrap.forEach(item => {
                item.style.width = `calc(100% - ${scroll}px)`;
            });
        });
    });
}

function calcScroll() {
    let div = document.createElement('div');

    div.style.width = '50px';
    div.style.height = '50px';
    div.style.overflowY = 'scroll';
    div.style.visibility = 'hidden';

    document.body.appendChild(div);
    let scrollWidth = div.offsetWidth - div.clientWidth;
    div.remove();

    return scrollWidth;
}


bindModal('.back-call', '.modal-back-call');
bindModal('.entrance-not', '.modal-entrance');
bindModal('.forget', '.modal-password');
bindModal('.show-registration__btn', '.modal-registration');
bindModal('.card__basket', '.modal-product');
bindModal('.show-edit-data', '.modal-edit-data');
bindModal('.show-edit-address', '.modal-edit-address');
bindModal('.btn-edit-requisites', '.modal-edit-requisites');
bindModal('.adress-item__remove', '.modal-remove-address');
bindModal('.person-area-exit', '.modal-person-area-exit');
bindModal('.purchase-history-item__remove', '.modal-remove-order');
bindModal('.buy-click', '.modal-buy-click');
bindModal('.wholesale-price', '.modal-wholesale-cost ');
bindModal('.basket-item__remove', '.modal-remove-basket');

//////////////////////////////////////////////////////////////////////////////


function closeModal() {
    const modalAll = document.querySelectorAll('.modal-wrap');

    modalAll.forEach(item => {
        item.classList.remove('active');
    });
    document.body.style.overflow = "";
    document.body.style.marginRight = '';
    header.style.width = '';
    modaWrap.forEach(item => {
        item.style.width = '';
    });
}

function closeAllModal() {
    const modalAll = document.querySelectorAll('.modal-wrap');
    const modalClose = document.querySelectorAll('.modal__close');

    modalClose.forEach(item => {
        item.addEventListener('click', function (e) {
            closeModal();
        });

    });

    modalAll.forEach(item => {
        item.addEventListener('click', function (e) {
            if (e.target === item) {
                item.classList.remove('active');
                document.body.style.overflow = "";
                document.body.style.marginRight = '';
                header.style.width = '';
                modaWrap.forEach(item => {
                    item.style.width = '';
                });
            }
        });
    });
}

closeAllModal();

///////////////////////////////////////////////////////////////////////////////

function showThanks() {
    const modal = document.querySelector('.modal-thanks');
    modal.classList.add('active');
    document.body.style.overflow = "hidden";
}

function showThanksRegistration() {
    const modal = document.querySelector('.modal-thanks-registration');
    modal.classList.add('active');
    document.body.style.overflow = "hidden";
}

function showModalPost() {
    const modal = document.querySelector('.modal-post');
    modal.classList.add('active');
    document.body.style.overflow = "hidden";
}

function showModalSubscription() {
    const modal = document.querySelector('.modal-thanks-subscription');
    modal.classList.add('active');
    document.body.style.overflow = "hidden";
}






